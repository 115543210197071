#ProductList .table-link {
  color: var(--secondary);
}

#ProductList .status-icon.public {
  color: var(--success-green);
}

#ProductList .status-icon.staged {
  color: #FFFF00;
}

#ProductList .status-icon.archived {
  color: rgb(82, 82, 82);
}

#ProductList .status-icon.private {
  color: var(--error-red);
}

#ProductList .edit-link {
  color: var(--secondary);
}