#AnalysisTable {
  max-width: 90%;
  margin: auto;
}

#AnalysisTable .table-link {
  color: var(--secondary);
}


#AnalysisTable .table-bordered,
#AnalysisTable .table-bordered table,
#AnalysisTable .table-bordered thead,
#AnalysisTable .table-bordered tr,
#AnalysisTable .table-bordered th,
#AnalysisTable .table-bordered td {
    border: none;
    padding: 0.5rem;
}

#AnalysisTable .table-bordered {
  margin-bottom: 0;
}

#AnalysisTable th {
  font-size: 0.8rem;
  white-space: nowrap;
  width: 1%;
}

#AnalysisTable td {
  font-size: 0.75rem;
  white-space: nowrap;
  width: 1%;
}

#AnalysisTable .table-bordered {
  width: auto;
}

#AnalysisTable .table-bordered tr.incident-table-header {
  color: white;
  background-color: var(--primary);
}

#AnalysisTable .table-bordered tr.incident-table-header th:focus {
  outline: none;
  background-color: var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

#AnalysisTable .table-bordered tr.incident-table-header th.sortable {
  cursor: pointer;
}

#AnalysisTable .table-bordered tr.selected-incident-row,
#AnalysisTable .table-bordered tr.selected-incident-row td{
  background-color: var(--primary-dark);
}

#AnalysisTable tr.selected-incident-row .incident-name {
  color: var(--on-primary);
}

#AnalysisTable table.table-bordered {
  border-collapse: separate;
  border-spacing: 0;
}

#AnalysisTable .table-bordered tr,
#AnalysisTable .table-bordered td {
  color: var(--on-background);
  background-color: var(--container-light);
}

#AnalysisTable table.table-bordered thead tr th {
  border-top: 1px solid var(--primary-light);
}

#AnalysisTable thead tr:first-child th:first-child {
  border-radius: 1em 0 0 0;
  border-left: 1px solid var(--primary-light);
}

#AnalysisTable thead tr:first-child th:last-child {
  border-radius: 0 1em 0 0;
  border-right: 1px solid var(--primary-light);
}

#AnalysisTable tbody tr:last-child td:first-child {
  border-radius: 0 0 0 1em;
}

#AnalysisTable tbody tr:last-child td:last-child {
  border-radius: 0 0 1em 0;
}


#AnalysisTable table.table-bordered tbody tr td:first-child {
  border-left: 1px solid var(--primary-light);
}

#AnalysisTable table.table-bordered tbody tr td:last-child {
  border-right: 1px solid var(--primary-light);
  white-space: normal;
}

#AnalysisTable table.table-bordered tbody tr:last-child td {
  border-bottom: 1px solid var(--primary-light);
}

#AnalysisTable .table-bordered tr .label.label-danger {
font-size: 0.8rem;
margin-left: 0px;
}

#AnalysisTable .table-bordered td.react-bs-table-no-data {
  border-radius: 0 0 1em 1em;
}

#AnalysisTable tr.selected-incident-row  .label,
#AnalysisTable tr.selected-incident-row td {
    color: var(--on-primary);
}