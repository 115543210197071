body {
  background-color: var(--background);
  height: 100%;
  overflow: hidden;
}

#App {
  padding: 50px 0px 40px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  background-color: var(--background);
  min-height: 100vh;
  color: var(--on-background);

}
