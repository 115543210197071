#EditAdvisoryDetails #EditAdvisoryDetailsContainer {
  width: 100%;
  margin: 10px auto;
  padding: 1rem;
  background-color: var(--container);
  border-radius: 0.5rem;
}

#EditAdvisoryDetails .stage-control {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#EditAdvisoryDetails .stage-control-button {
  margin: 0 0.5rem;
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#EditAdvisoryDetails #next-stage:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}

#EditAdvisoryDetails .label-preview {
  color: var(--secondary);
  font-size: 0.8rem;
}

#EditAdvisoryDetails .markdown-preview {
  background-color: var(--container-light);
  border-radius: 0.5rem;
  text-align: left;
}

#EditAdvisoryDetails a {
  color: var(--secondary);
}

#EditAdvisoryDetails .form-text {
  text-align: left;
}

#EditAdvisoryDetails .error-message-container {
  color: var(--error-red);
}