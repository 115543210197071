#MalwareHashDetails {
  max-width: 95%;
  margin: 20px auto;
}

#MalwareHashDetails .threat-stats-overview{
  background-color: var(--container);
  border: 1px solid var(--primary-light);
  border-radius: 0.5rem;
  max-width: 1000px;
  margin: auto;
}

#MalwareHashDetails .malware-threat-stat {
  font-size: 0.8rem;
}

#MalwareHashDetails .malware-threat-stat .stat-label {
  font-weight: bold;
}

#MalwareHashDetails .threat-details-section {
  max-width: 1000px;
  background-color: var(--container);
  border: 1px solid var(--primary-light);
  border-radius: 0.5rem;
  margin: 1rem auto;
  padding: 0.5rem;
}

#MalwareHashDetails .threat-details-content {
  font-size: 0.8rem;
  text-align: left;
  background-color: var(--container);
  border-radius: 0.5rem;
  margin: 1rem auto;
  padding: 0.5rem;
  display: inline-block;
  width: 100%;

}

#MalwareHashDetails .threat-details-content .inline-detail {
  float: left;
  margin-right: 1rem;
}

#MalwareHashDetails .threat-details-content .inline-detail.right {
  float: right;
}

#MalwareHashDetails .threat-details-content .threat-detail {
  word-wrap: break-word;
}

#MalwareHashDetails .threat-details-content .detail-label {
  font-weight: bold;
}

#MalwareHashDetails .threat-details-content pre {
  color: var(--on-background);
}

#MalwareHashDetails .no-threat-report .btn {
  margin-top: 0.5rem;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#MalwareHashDetails .no-threat-report .btn:focus {
  outline: none;
  box-shadow: none;
}

#MalwareHashDetails .no-threat-report .btn:hover {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

#MalwareHashDetails .no-threat-report .btn:active {
  box-shadow: 0 0 0.2rem 0.2rem var(--secondary-light);
}

#MalwareHashDetails .processing-request {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

#MalwareHashDetails .interesting-string-type {
  font-weight: bold;
}

#MalwareHashDetails .interesting-string ul {
  margin-bottom: 0;
}

#MalwareHashDetails .download-icon {
  color: var(--on-background);
}