#LoadingOverlay {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000aa;
  z-index: 98;
}

#LoadingOverlay #loading-overlay-content {
  margin-top: -15rem;
}

#LoadingOverlay .loading-spinner {
  font-size: 3rem;
}