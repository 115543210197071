#MalwareHashes .report-clean {
  color: var(--success-green);
}

#MalwareHashes .report-empty {
  color: #FFFF00;
}

#MalwareHashes .report-found {
  color: var(--error-red);
}

#MalwareHashes .table-link {
  color: var(--secondary);
}

#MalwareHashes .download-icon {
  color: var(--on-background);
}