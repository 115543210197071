#ManageUsers .toggle-account-status {
  color: var(--secondary);
  width: fit-content;
  text-align: center;
  margin: auto;
}

#ManageUsers .toggle-account-status:focus {
  outline: none;
}

#ManageUsers .role-select{
  width: fit-content;

  margin: auto;
  padding: 0.25rem;
  height: 1.7rem;
  background-color: var(--container-light);
  color: var(--on-background);
}