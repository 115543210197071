#AdvisoryList .table-link {
  color: var(--secondary);
}

#AdvisoryList .status-icon.public {
  color: var(--success-green);
}

#AdvisoryList .status-icon.staged {
  color: #FFFF00;
}

#AdvisoryList .status-icon.archived {
  color: rgb(82, 82, 82);
}

#AdvisoryList .status-icon.private {
  color: var(--error-red);
}