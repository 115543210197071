#AdvisoryVisibilityControls {
  background-color: var(--container);
  max-width: 800px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

#AdvisoryVisibilityControls .current-visibility {
  font-size: 1.1rem;
}

#AdvisoryVisibilityControls .current-visibility .visibility-value {
  color: var(--secondary);
  font-weight: bold;
  font-size: 1.5rem;
}

#AdvisoryVisibilityControls .visibility-control-button {
  margin: 0.5rem 0.5rem;
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#AdvisoryVisibilityControls .visibility-control-button:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}

#AdvisoryVisibilityControls .visibility-control-button:disabled {
  background-color: var(--secondary-light);
}