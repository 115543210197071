.FileThreatTile {
  background-color: var(--container-light);
  border: 1px solid var(--primary-light);
  border-radius: 0.5rem;
  margin: 0.5rem;
  display: inline-block;
  width: 100%;
}

.FileThreatTile .file-report {
  margin-left: 0.5rem;
}

.FileThreatTile .analysis-status {
  float: left;
  height: 2rem;
  width: 2rem;
  margin: 0.8rem 0rem 0.3rem 0.5rem;
  border: 2px solid var(--primary-light);
  border-radius: 0.5rem;
  text-align: center;
}

.FileThreatTile .analysis-status.analysis-complete {
  background-color: var(--success-green);
}

.FileThreatTile .analysis-status.needs-analysis {
  background-color: var(--error-red);
}

.FileThreatTile .malware-threat-title a {
  font-size: 1rem;
  text-align: left;
  margin: 0.8rem;
  color: var(--secondary);
  float: left;
}

.FileThreatTile .malware-threat-stat {
  text-align: left;
}

.FileThreatTile .malware-threat-stat .stat-label {
  font-weight: bold;
}

.FileThreatTile .file-report-status,
.FileThreatTile .hash-link,
.FileThreatTile .report-info {
  float: left;
  margin-right: 0.5rem;
}

.FileThreatTile .report-info .label {
  font-weight: bold;
}

.FileThreatTile .hash-link a {
  color: var(--secondary);
}

.FileThreatTile .report-clean {
  color: var(--success-green);
}

.FileThreatTile .report-empty {
  color: #FFFF00;
}

.FileThreatTile .report-found {
  color: var(--error-red);
}