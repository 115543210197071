#ViewAnalyses {
  margin: 15px;
  text-align: left;
}

#ViewAnalyses #current-analysis,
#ViewAnalyses .previous-analysis {
  background-color: var(--container-light);
  border-radius: 0.5rem;
}

#ViewAnalyses #current-analysis {
  border: 1px solid var(--secondary);
}

#ViewAnalyses #current-analysis .analysis-details {
  border-bottom: 1px solid var(--on-background);
}

#ViewAnalyses .previous-analysis {
  margin: 0.5rem 0 0;
}

#ViewAnalyses h3 {
  margin-top: 0.5rem;
}

#ViewAnalyses .disable {
  position: absolute;
  top: 0;
  right: 1rem;
  cursor: pointer;
}

#ViewAnalyses .create-analysis {
  margin-top: 2rem;
}