.Login {
    height: calc(100vh - 90px);;
}

.Login .Content {
    min-height: calc(100vh - 150px);
    padding-top: 10px;
}

.login-form {
    border: 1px solid var(--primary-light);
    border-radius: 5px;
    background-color: var(--container);
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
.login-form-error {
    margin-bottom: 10px;
    color: var(--error-red)
}

.login-form-submit{
    margin-bottom: 5px;
}

.login-form-submit.btn.btn-primary {
    background-color: var(--secondary);
    border-color: var(--secondary);
    cursor: pointer;
}

.login-form-submit.btn.btn-primary:focus {
    background-color: var(--secondary);
    border-color: var(--secondary);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.login-form-submit.btn.btn-primary:hover{
    background-color: var(--secondary-light);
    border-color: var(--secondary-light);
}

.login-form-submit.btn.btn-primary:active,
.login-form-submit.btn.btn-primary:focus:active {
    background-color: var(--secondary-light);
    border-color: var(--secondary-light);
    box-shadow: 0 0 2px 1px var(--secondary-light);
}

.login-form-submit.btn.btn-primary:focus, 
.login-form-submit.btn.btn-primary:active,
.login-form-submit.btn.btn-primary:active:focus {
    outline: none;
}