#Products .management-links .btn {
  float: right;
  margin-right: 10px;
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#Products .management-links .btn:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}