#CreateAnalysis #submit-analysis-button {
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);

}

#CreateAnalysis #submit-analysis-button:focus {
  outline: none;
  box-shadow: none;
}

#CreateAnalysis #submit-analysis-button:active {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
  box-shadow: 0 0 0.2rem 0.1rem var(--secondary-light);
}

#CreateAnalysis .submit-pending {
  float: right;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

#CreateAnalysis .submit-pending-icon {
  font-size: 1.5rem;
}

#CreateAnalysis textarea {
  color: var(--on-background);
  background-color: var(--container-light);
}

#CreateAnalysis textarea::placeholder {
  color: var(--on-background);
  opacity: 0.7;
}

#CreateAnalysis textarea:focus {
  border-color: var(--secondary);
  box-shadow: 0 0 0.3rem 0.1rem var(--secondary)
}