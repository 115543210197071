#Search {
  margin-top:20px;
}

#Search .search-container {
  padding: 1rem 1rem 0;
  background-color: var(--container);
  border: 1px solid var(--primary-light);
  max-width: 90%;
  margin: 1rem auto;
}