#BreachDatabaseList .table-link {
  color: var(--secondary);
}

#BreachDatabaseList .status-icon.enabled {
  color: var(--success-green);
}

#BreachDatabaseList .status-icon.pending {
  color: #FFFF00;
}

#BreachDatabaseList .status-icon.new {
  color: blue;
}

#BreachDatabaseList .status-icon.error {
  color: var(--error-red);
}