#Header {
    position: fixed;
    top:0;
    left: 0;
    height: 50px;
    width: 100vw;
    background-color: var(--primary-dark);
    border-bottom: 1px solid var(--secondary);
    box-shadow: 0px 0px 5px 0px var(--secondary);
    z-index: 100;
}

.header-title {
    position: absolute;
    width: 100%;
    text-align: center;
    color: var(--on-primary);
}