#ConfirmOverlay {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000aa;
  z-index: 101;
}

#ConfirmOverlay #overlay-content {
  margin-top: -3rem;
  background-color: var(--container);
  padding: 2rem;
  border-radius: 0.5rem;
}

#ConfirmOverlay #overlay-content .btn {
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: var(--secondary);
  border-color: var(--secondary);
}


#ConfirmOverlay #overlay-content .btn:focus {
  outline: none;
  box-shadow: none;
}

#ConfirmOverlay #overlay-content .btn:hover {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

#ConfirmOverlay #overlay-content .btn:active {
  box-shadow: 0 0 0.2rem 0.2rem var(--secondary-light);
}