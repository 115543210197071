#UpdateBreachForm .form-section label{
  float: left;
}

#UpdateBreachForm .form-section .switch-row{
  margin: 10px 0;
}

#UpdateBreachForm .form-section .switch-row .settings-switch{
  margin-left: 3px;
}

#UpdateBreachForm .form-section #multiselectContainerReact {
  clear: both;
}

#UpdateBreachForm .form-section #multiselectContainerReact .search-wrapper {
  background-color: white;
}

#UpdateBreachForm .form-section #multiselectContainerReact .search-wrapper .chip {
  background-color: var(--secondary);
}

#UpdateBreachForm .form-section #multiselectContainerReact .optionListContainer {
  color: black;
}

#UpdateBreachForm .form-section #multiselectContainerReact .optionListContainer li {
  padding: 2px;
}

#UpdateBreachForm .form-section #multiselectContainerReact .optionListContainer li:hover {
  background-color: var(--secondary);
}

#UpdateBreachForm #submit-breach {
  float: left;
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#UpdateBreachForm #submit-breach:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}

#UpdateBreachForm .submit-pending {
  float: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

#UpdateBreachForm .error-message {
  margin-left: 1rem;
  padding-top: 0.5rem;
  display: inline-block;
  width: fit-content;
  text-align: left;
}