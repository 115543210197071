.ChangePassword {
    height: calc(100vh - 90px);;
}

.ChangePassword .Content {
    min-height: calc(100vh - 150px);
    padding-top: 10px;
}

.change-password-form {
    border: 1px solid var(--primary-light);
    border-radius: 5px;
    background-color: var(--container);
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}



.change-password-form-error {
    margin-bottom: 10px;
    color: var(--error-red);
}

.change-password-link,
.change-password-link:hover {
    color: var(--secondary);
}

.change-password-form-submit{
    margin-bottom: 5px;
}

.change-password-form-submit.btn.btn-primary,
.change-password-form-submit.btn.btn-primary:hover {
    background-color: var(--secondary);
    border-color: var(--secondary);
    cursor: pointer;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.change-password-form-submit.btn.btn-primary:hover,
.change-password-form-submit.btn.btn-primary:active,
.change-password-form-submit.btn.btn-primary:focus:active {
    background-color: var(--secondary-light);
    border-color: var(--secondary-light);
}

.change-password-form-submit.btn.btn-primary:active,
.change-password-form-submit.btn.btn-primary:focus:active {
    box-shadow: 0 0 2px 1px var(--secondary-light);
}

.change-password-form-submit.btn.btn-primary:focus, 
.change-password-form-submit.btn.btn-primary:active,
.change-password-form-submit.btn.btn-primary:active:focus {
    outline: none;
}