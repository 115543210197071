#MalwareThreatDetails {
  margin-top: 20px;
}

#MalwareThreatDetails .threat-stats-overview{
  background-color: var(--container);
  border: 1px solid var(--primary-light);
  border-radius: 0.5rem;
  max-width: 1000px;
  margin: auto;
}

#MalwareThreatDetails .malware-threat-stat {
  font-size: 0.8rem;
}

#MalwareThreatDetails .malware-threat-stat .stat-label {
  font-weight: bold;
}

#MalwareThreatDetails .threat-details-section {
  max-width: 1000px;
  background-color: var(--container);
  border: 1px solid var(--primary-light);
  border-radius: 0.5rem;
  margin: 1rem auto;
  padding: 0.5rem;
}