#AuthBar {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
}
/*
@media (max-width :600px) {
    #AuthBar {
        display: none;
    }
}*/
.auth-user-icon {
    float: left;
    padding-top: 5px;
    margin-right: 8px;
}

.auth-user-icon:focus {
    outline: none;
}

.auth-user-icon .fa-user-circle {
    color: white;
    height: 30px;
    width: 30px;
}

.auth-user-icon .fa-user-circle:hover {
    color: #ed8323;
}

.auth-button {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid var(--on-background);
    padding: 0.3rem;
    border-radius: 0.4rem;
    background-color: var(--primary-dark);
}

.auth-button a {
    color: var(--on-background);
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: block;
}

.auth-button .btn.btn-primary {
    background-color: var(--primary-dark);
    border-color: white;
    cursor: pointer;
}

.auth-button:focus, 
.auth-button:active,
.auth-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}


.auth-button .btn.btn-primary:focus {
    background-color: var(--primary-dark);
    border-color: white;
}

.auth-button:hover{
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.auth-button:active,
.auth-button:focus:active {
    background-color: var(--secondary-light);
    border-color: var(--secondary-light);
    box-shadow: 0 0 2px 1px var(--secondary-light);
}


#authbar-user-avatar {
    cursor: pointer;
}

.auth-user-icon {
    float: right;
    cursor: pointer;
    -webkit-app-region: no-drag;
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid var(--secondary);
    overflow:hidden;
}

.auth-user-icon img {
    height: 40px;
}