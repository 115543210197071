#AddProduct {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000aa;
  z-index: 101;
}

#confirm-content {
  position: absolute;
  height: fit-content;
  width: fit-content;
  width: 500px;
  padding: 0.5rem;
  border: 1px solid var(--secondary);
  background-color: var(--container);
  z-index: 99;
  border-radius: 0.5rem;;
  top: 50px;
}

#AddProduct .confirm-message {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
}

#AddProduct .error-message {
  text-align: center;
  font-size: 0.9rem;
  color: var(--secondary);
}

#AddProduct .confirm-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

#AddProduct .PrimaryButton {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}