#ProcessBreachInstructions .dashboard-item {
  max-width: 80%;
  margin: 5px auto;
  background-color: var(--container);
  border-radius: 0.5rem;
  border: 1px solid var(--secondary);
  padding: 5px;
}

#ProcessBreachInstructions .dashboard-item p,ol {
  text-align: center;
}

#ProcessBreachInstructions .nav-link {
  margin: 5px auto;
  background-color: var(--secondary);
  border-radius: 0.5rem;
}

#ProcessBreachInstructions .nav-link:hover {
  color: var(--on-secondary);
}

#ProcessBreachInstructions .nav-link:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}