.ProfileMenu {
    color: var(--on-background);
    border: none;
    border-radius: 5px;
    background-color: var(--container-light);
    border: 1px solid var(--primary-light);
    box-shadow: 0px 0px 2px 1px var(--primary-light);
    z-index: 99;
    position: absolute;
    width: 200px;
    top: 50px;
    right: 10px;
    display: inline-block;
    padding: 0;
    -webkit-app-region: no-drag;
}

.ProfileMenu.windows {
    top: 75px;
}

.profile-menu-header{
    border-bottom: 1px solid var(--primary);
    height: 50px;
    width: 200px;
    overflow-x:hidden;
    margin:0;
}

.profile-menu-header-img {
    margin-top:5px;
    margin-left: 5px;
    height:40px;
    border-radius:50%;
    float: left;
}


.profile-menu-header-user {
    font-size: 1rem;
    text-overflow:clip;
    white-space: nowrap;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 60px;
    margin-right: 10px;
    width: max-content;
}

.profile-menu-item {
    cursor: pointer;
    margin: 0;
    font-size: 0.9rem;
    height: 30px;
    padding-top: 3px;
    border-bottom: 1px solid var(--primary);
    min-width: 198px;
}

.profile-menu-item:hover {
    border-radius: 5px;
    background-color: var(--secondary);
}

.profile-menu-item a {
    color: white;
    display: inline-block;
    padding-right: 15px;
    width: 100%;
}

.profile-menu-item-icon {
    margin-top:5px;
    float:left;
}

.profile-menu-item-label {
    float: left;
    margin-left: 10px;
}