#LoadingMessageContainer {
  margin: 40px auto;
  width: 500px;
  background-color: var(--container);
  border-radius: 0.5rem;
}

#LoadingMessageContainer .searching-spinner {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2rem;
}