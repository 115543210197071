.PrimaryButton.btn-primary:not(:disabled):not(.disabled) {
  margin: 0.5rem 0.5rem;
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.PrimaryButton.btn-primary.disabled,
.PrimaryButton.btn-primary:disabled {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

.PrimaryButton.btn-primary:not(:disabled):not(.disabled):hover,
.PrimaryButton.btn-primary:not(:disabled):not(.disabled):focus,
.PrimaryButton.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

.PrimaryButton.btn-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}

.PrimaryButton.btn-primary:not(:disabled):not(.disabled):disabled {
  background-color: var(--secondary-light);
}