.result-contents {
  margin: 0.25rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
  background-color: var(--container-light);
}

.result-contents.malicious {
  background-color: var(--error-red);
}

.ScanVendorResult .scan-vendor {
  font-size: 1.2rem;
}

.ScanVendorResult .result-label {
  font-weight: bold;
}

.ScanVendorResult .scan-result {
  font-size: 0.7rem;
}
