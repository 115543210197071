#AdvisoryDetailsView #AdvisoryDetailsViewContainer {
  width: 100%;
  margin: 10px auto;
  padding: 1rem;
  background-color: var(--container);
  border-radius: 0.5rem;
}

#AdvisoryDetailsView .advisory-title {
  color: var(--secondary);
  text-align: left;
}

#AdvisoryDetailsView .advisory-title .title-value {
  color: white;
}

#AdvisoryDetailsView .advisory-details-content {
  background-color: var(--container-light);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

#AdvisoryDetailsView .advisory-references-list,  .affected-products-content{
  background-color: var(--container-light);
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: left;
}

#AdvisoryDetailsView .detail-label {
  font-weight: bold;
  text-align: left;
  color: var(--secondary);
}

#AdvisoryDetailsView .detail-item {
  text-align: left;
}

#AdvisoryDetailsView .stage-control {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#AdvisoryDetailsView .stage-control-button {
  margin: 0 0.5rem;
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#AdvisoryDetailsView #next-stage:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}

#AdvisoryDetailsView .label-preview {
  color: var(--secondary);
  font-size: 0.8rem;
}

#AdvisoryDetailsView .markdown-preview {
  background-color: var(--container-light);
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: left;
}

#AdvisoryDetailsView a {
  color: var(--secondary);
}

#AdvisoryDetailsView .form-text {
  text-align: left;
}

#AdvisoryDetailsView .affected-products-content .PrimaryButton {
  float: right;
}