:root {
  --primary: #191919;
  --primary-light: #3f3f3f;
  --primary-dark: #0f0f0f;
  --secondary: #d32f2f;
  --secondary-light: #ff6659;
  --secondary-dark: #9a0007;
  --background: #121212;
  --container: #1d1d1d;
  --container-light: #2b2b2b;
  --surface: #F5F5F6;
  --on-primary: #ffffff;
  --on-secondary: #ffffff;
  --on-background: #ffffff;
  --on-surface: #ffffff;
  --success-green: #00cc00;
  --error-red: #d10606;
}

html,body {
  height: 100%;
  background-color: var(--background);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--on-background);
    background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}