#SelectProductFamily #SelectProductFamilyContainer {
  width: 600px;
  margin: 10px auto;
  padding: 1rem;
  background-color: var(--container);
  border-radius: 0.5rem;
}

#SelectProductFamily #multiselectContainerReact {
  clear: both;
}

#SelectProductFamily #multiselectContainerReact .search-wrapper {
  background-color: white;
}

#SelectProductFamily #multiselectContainerReact .search-wrapper .chip {
  background-color: var(--secondary);
}

#SelectProductFamily #multiselectContainerReact .optionListContainer {
  color: black;
}

#SelectProductFamily #multiselectContainerReact .optionListContainer li {
  padding: 2px;
}

#SelectProductFamily #multiselectContainerReact .optionListContainer li:hover {
  background-color: var(--secondary);
}

#SelectProductFamily .stage-control {
  margin-top: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#SelectProductFamily .stage-control-button {
  margin: 0 0.5rem;
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#SelectProductFamily #next-stage:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}