#AnalysisStatBlock .stat-item {
  font-size: 1.5rem;
}

#AnalysisStatBlock .stat-item.label {
  text-align: center;
}

#AnalysisStatBlock .count-link {
  color: var(--secondary);
}