#UploadImage {
  clear: both;
}

#UploadImage .breach-image {
  background-color: white;
  height: 100px;
  border: 1px solid white;
}

#UploadImage .upload-image-button{
  border-radius: 0.2rem;
  background-color: var(--secondary);
  color: var(--on-secondary);
  border-color: var(--secondary);
}

#UploadImage .upload-image-button:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}