#AddUser {
  max-width: 90%;
  margin: auto;
}

#AddUser .btn.btn-primary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  cursor: pointer;
}

#AddUser .btn.btn-primary:focus {
  background-color: var(--secondary);
  border-color: var(--secondary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

#AddUser .btn.btn-primary:hover{
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

#AddUser .btn.btn-primary:active,
#AddUser .btn.btn-primary:focus:active {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
  box-shadow: 0 0 2px 1px var(--secondary-light);
}

#AddUser .btn.btn-primary:focus, 
#AddUser .btn.btn-primary:active,
#AddUser .btn.btn-primary:active:focus {
  outline: none;
}