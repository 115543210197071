#ThreatSearchForm .form-group {
  margin-bottom: 0;
}

#ThreatSearchForm .search-field {
  background-color: var(--container-light);
  color: var(--on-background);
}

#ThreatSearchForm .search-field:focus {
  border-color: var(--secondary);
  outline: none;
  box-shadow: none;
}

#ThreatSearchForm .btn.btn-primary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

#ThreatSearchForm .btn.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

#ThreatSearchForm .btn.btn-primary:hover {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

#ThreatSearchForm .btn.btn-primary:active {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
  box-shadow: 0 0 0 0.2rem var(--secondary-light);
}

#ThreatSearchForm .search-pending {
  float: right;
  margin: 0.3rem;
}

#ThreatSearchForm .search-pending .search-pending-icon {
  font-size: 1.7rem;
}

#ThreatSearchForm .advanced-toggle .advanced-toggle-button {
  cursor: pointer;
  width: 30px;
  margin: auto;
}

#ThreatSearchForm .advanced-toggle .advanced-toggle-button:focus {
  outline: none;
}

#ThreatSearchForm .advanced-search-section {
  margin-top: 1rem;
}

#ThreatSearchForm .advanced-search-controls {
  text-align: center;
}

@media (min-width :992px) {
  #ThreatSearchForm .advanced-search-controls {
    text-align: right;
  }
  #ThreatSearchForm .advanced-search-controls .form-label {
    padding-right: 0;
  }
}

#ThreatSearchForm .advanced-search-controls .form-control {
  background-color: var(--container-light);
  color: var(--on-background);
}

#ThreatSearchForm .advanced-search-controls .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--on-background);
}

#ThreatSearchForm .advanced-search-controls .form-control:focus:active {
  border-color: var(--secondary);
}