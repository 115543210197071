#BreachStatusBlock .status-icon {
  border: 1px solid white;
  border-radius: 4px;
  color: var(--on-background);
}

#BreachStatusBlock .status-icon.completed {
  background-color: var(--success-green);
  color: var(--on-background);
}

#BreachStatusBlock .status-icon.error {
  background-color: var(--error-red);
  color: var(--on-background);
}

#BreachStatusBlock .status-icon.pending {
  background-color: #FFFF00;
  color: black;
}

#BreachStatusBlock .status-icon.incomplete {
  opacity: 0.5;
}