#HeaderNavMenu {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

@media (max-width :730px) {
  #HeaderNavMenu {
      display: none;
  }
}

.nav-link {
  color: white;
  float:left;
  padding-left: 10px;
  padding-right: 10px;
}

.nav-link:focus {
  outline: none;
}
.nav-link:hover {
  color: var(--secondary);
}

.nav-link:active {
  color: var(--secondary-light);
}